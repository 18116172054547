import React from "react";
import SpotlightContainer from "../spotlight";
import UiPath from "../../../images/uipath.png";
import Image from "../../../images/pa3.png";

function Project() {
  const jobData = {
    jobName: "Talk with me",
    button: "Download",
    link: "https://drive.google.com/drive/folders/11N-WU9Hv-ysSQUr4AW3Qo_tUpNi2eOcx?usp=sharing",
    image: Image,
    stack: [
      {
        name: "UiPath",
        image: UiPath,
      },
    ],

    description:
      "This is an engaging RPA bot using UiPath that automates the process of sending messages from a designated Gmail account. This bot was designed for fun and experimentation, showcasing the ability to interact with Gmail’s interface and automate messaging tasks.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
